




















































































import Vue from 'vue'
import Switches from 'vue-switches'
import { LiveSettingsT } from '@/interfaces/firestore'
import {
  BrandInDB,
  BrandsApiFactory,
  KitchenPauseDetail,
  KitchenPausingRequest,
  KitchensApiFactory,
} from '@/api-client'

const api = KitchensApiFactory(undefined, '')
const brandsAPI = BrandsApiFactory(undefined, '')

export default Vue.extend({
  components: {
    Switches,
  },
  data: () => ({
    isPauseModeActivated: false,
    fetchBusy: false,
    isRefreshingStatus: false,
    isPostRequestSending: false,
    brandsMapping: {} as Record<string, BrandInDB>,
    pauseDetail: null as null | KitchenPauseDetail,
    firebaseUnsubscribe: null as null | (() => void),
  }),
  computed: {
    brandsFetched(): boolean {
      return !this.fetchBusy && Object.keys(this.brandsMapping).length > 0
    },
    busy(): boolean {
      return (
        this.fetchBusy || this.isRefreshingStatus || this.isPostRequestSending
      )
    },
    isPausedPartial(): boolean {
      return (
        this.pauseDetail === null ||
        this.pauseDetail.pausable_brand_ids.length > 0
      )
    },
    restaurants(): { id: string; name: string; paused: boolean }[] {
      const pauseDetail = this.pauseDetail
      if (!(pauseDetail && this.brandsFetched)) return []
      const paused = pauseDetail.paused_brand_ids.map((brandID) => {
        const brand = this.brandsMapping[brandID]
        return {
          id: brandID,
          name: brand?.pos_name || 'Marque inconnue',
          paused: true,
        }
      })
      const pausable = pauseDetail.pausable_brand_ids.map((brandID) => {
        const brand = this.brandsMapping[brandID]
        return {
          id: brandID,
          name: brand?.pos_name || 'Marque inconnue',
          paused: false,
        }
      })
      return paused
        .concat(pausable)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
  },

  created() {
    // Fetch brands and per-restaurant pausing details
    this.$Progress.start()
    this.fetchBrands()
      .then(this.fetchPauseDetails)
      .then(() => this.$Progress.finish())
      .catch(() => this.$Progress.fail())
  },

  mounted() {
    const kitchenID = this.$accessor.settings.lastSelectedKitchen
    if (!kitchenID) {
      this.$sentry.captureMessage(
        'Assertion failure: `lastSelectedKitchen` is `null`'
      )
      this.$accessor.auth.resetUser()
      this.$accessor.settings.resetKitchenSelection()
      this.$router.push('/login') // Force redirect to the log-in page
      return
    }

    // Subscribe to the global pause status
    this.firebaseUnsubscribe = this.$firebase
      .firestore()
      .collection('kitchens')
      .doc(kitchenID)
      .onSnapshot((kitchenDoc) => {
        const liveSettings: LiveSettingsT = kitchenDoc.data()
        if (!this.isPostRequestSending) this.fetchPauseDetails()
        this.isPauseModeActivated = !!liveSettings?.paused_since
      })
  },
  beforeDestroy() {
    if (this.firebaseUnsubscribe) this.firebaseUnsubscribe()
  },
  methods: {
    async toggle(shouldPause: boolean, brandID?: string) {
      const kitchenID = this.$accessor.settings.lastSelectedKitchen
      if (!kitchenID || this.isPostRequestSending) return
      this.isPostRequestSending = true
      this.$Progress.start()

      const request: KitchenPausingRequest = {
        restricted_to_brand_ids: brandID ? [brandID] : undefined,
      }
      try {
        if (shouldPause)
          this.pauseDetail = (
            await api.pauseKitchenKitchensKitchenIdPausePost(kitchenID, request)
          ).data
        else
          this.pauseDetail = (
            await api.unpauseKitchenKitchensKitchenIdUnpausePost(
              kitchenID,
              request
            )
          ).data
      } catch {
        this.isPostRequestSending = false
        await this.fetchPauseDetails()
        this.$Progress.fail()
      } finally {
        this.isPostRequestSending = false
        this.$Progress.finish()
      }
    },

    async fetchBrands() {
      if (this.fetchBusy) return

      this.fetchBusy = true
      try {
        const brands = (await brandsAPI.getBrandsBrandsGet()).data
        this.brandsMapping = Object.fromEntries(
          brands.map((brand) => [brand._id, brand])
        )
      } finally {
        this.fetchBusy = false
      }
    },

    async fetchPauseDetails() {
      const kitchenID = this.$accessor.settings.lastSelectedKitchen
      if (!kitchenID || this.isRefreshingStatus || !this.brandsFetched) return

      this.isRefreshingStatus = true
      try {
        this.pauseDetail = (
          await api.getAndUpdateKitchenPauseDetailKitchensKitchenIdPauseGet(
            kitchenID
          )
        ).data
      } finally {
        this.isRefreshingStatus = false
      }
    },
  },
})
